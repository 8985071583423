import './NavBar.css';
import  useWindowDimensions from "./hooks/useWindowDimensions.js"
import { useState } from 'react';
import Sidebar from './sidebar';



// Don't change logo here, just set new logo file to the same name as what is in here

function NavBar(){
    const {width, height} = useWindowDimensions();
    const [menuTrigger, setMenuTrigger] = useState(false);
    return (
        <div className='navbar-body'>
            <Sidebar isOpen={menuTrigger} toggleSidebar={() => setMenuTrigger(!menuTrigger)}/>
        <nav className='navbar'>
            <div className='navbar-center'>
                <a href='/'>
                    <img className="site-logo" alt = '' src="/EdensGateLogo.png" />
                </a>
            </div>
            {
                width > 550 ? 
                    <div className='navbar-right'>
                        <a className="navbar-button" href='/forms'>Submit Deck</a>
                        <a className="navbar-button" href='/community'>Community</a>
                        <a className='navbar-button' href='/about'>About</a>
                    </div>
                    :
                    <div className='navbar-menu-icon-shadow'>
                        <a onClick={() => setMenuTrigger(!menuTrigger)}><img className='navbar-menu-icon' src='./images/icons/menu_icon.png' alt=''/></a>
                    </div>
            }
        </nav>
        </div>
    );
}


export default NavBar