import './App.css';
import React from 'react';
import NavBar from './NavBar';
import Home from "./pages/Home";
import Character from './pages/character';
import DeckTutorial from './pages/deck_tutorial';
import About from './pages/about';
import Community from './pages/community';
import Forms from './pages/forms';
import Sidebar from './sidebar';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";


function App() {
  return (
    <Router>
      <NavBar/>
      <Routes>
        <Route index element={<Home/>}/>
        <Route path='/character' element={<Character/>}/>
        <Route path='/deck_tutorial' element={<DeckTutorial/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/community' element={<Community/>}/>
        <Route path='/forms' element={<Forms/>}/>
      </Routes>
      <div className='disclaimer-box'><text className='disclaimer-text'>Decks Of Eden is not officially involved with managing or producing Duelists of Eden.</text></div>
    </Router>
  );
}

export default App;
