import './Home.css';


// Function for building the character cards displayed in the grid
// anchor is set to the link leading to the selected character's pain
// Forgot what hover_text is for, just ignore it :p
function buildCard(route, character){
    return(
        <a href={'/character?char='+character}>
            <div className='grid-card' hover_text={character}>
                <img className='charCard' src={route} alt="Description"/>
            </div>
        </a>
    );
}

// Call the buildCard ffunction a million times to fill the grid
// To add new characters you will need to add another buildCard call with their sleeve image and their name
// The game likely isn't getting new characters so this should not be touched
function Home(){
    return (
        <div className='cardContainer'>
            <div className='characterCards'>
                {buildCard("/images/SaffronSleeve.png", "Saffron")}
                {buildCard("/images/SelicySleeve.png", "Selicy")}
                {buildCard("/images/HazelSleeve.png", "Hazel")}
                {buildCard("/images/TerraSleeve.png", "Terra")}
                {buildCard("/images/ShisoSleeve.png", "Shiso")}
                {buildCard("/images/VioletteSleeve.png", "Violette")}
                {buildCard("/images/RevaSleeve.png", "Reva")}
                {buildCard("/images/ChirettaSleeve.png", "Chiretta")}
                {buildCard("/images/GunnerSleeve.png", "Gunner")}
                {buildCard("/images/ShopkeeperSleeve.png", "Shopkeeper")}
                {buildCard("/images/HarissaSleeve.png", "Harissa")}
                {buildCard("/images/PerillaSleeve.png", "Perilla")}
                {buildCard("/images/TerrableSleeve.png", "Terrable")}
                {buildCard("/images/DreadwyrmSleeve.png", "Dreadwyrm")}
                {buildCard("/images/NeeraSleeve.png", "Neera")}
                {buildCard("/images/QueenSleeve.png", "Queen")}
                {buildCard("/images/MaypulSleeve.png", "Maypul")}
            </div>
        </div>
    )
}


export default Home