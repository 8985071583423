import './about.css'
import { useEffect } from 'react'

/*Text values*/
const text_whatis = "Decks Of Eden is a website meant to provide an easy way to find information on competitive decks for Duelists of Eden. Here you will not only be able to find decks from strong players, but also be able to find tutorials for these decks as well. This website is very much a work in progress, and will likely change significantly over time. "
const text_aboutme = "I play Duelists of Eden competitively and have been involved with the community for a while now. There's nothing I enjoy more than making a good deck and sharing it. I'm also an unemployed CS graduate so I currently have a bit too much time on my hands. If literally building a website can't get me a job in web programming, I'm cooked. Lets hope that won't be the case :D. In the official server and on social media I go by the name Windfall if you want to find me."
const text_futureplans= "I intend to continue working on this website over the next year or so. I want to add a lot of cool features and I will also take suggestions in Discord. You can find all of the features I plan to add, as well as updates in the forum I've created in the official Duelists of Eden Discord server.\n\nI currently cannot afford to pay for a database so I will be unable to implement certain features until then. The most notable feature being community deck lists which would enable anyone to submit a deck list and have it immediately appear on the site. Once I can afford to implement a backend, I'll do my best to make it happen. "

/*3 Sections*/
// This is simple enough, nothing special or complex
function About(){

    useEffect(()=>{
        document.title="About | Decks Of Eden"
    })
    return (
        <div className='about-body'>
            <header className='about-header'>What is Decks Of Eden?</header>
            <div className='about-box-whatis'>
                <text>{text_whatis}</text>
            </div>
            <header className='about-header'>About Me</header>
            <div className='about-box-whatis'>
                <text>{text_aboutme}</text>
            </div>
            <header className='about-header'>Future Plans</header>
            <div className='about-box-whatis'>
                <text>{text_futureplans}</text>
            </div>
        </div>

    );
}

export default About;