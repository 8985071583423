import './character.css';
import comp_decklists from './comp_decklists.json';
import descriptions from "./descriptions.json";
import spell_recommendations from "./spell_recommendations.json";
import deck_plan from './deck_plan.json'
import { useEffect } from 'react';
import  ClipboardButton from "../hooks/ClipboardButton.js";
import spell_links from "./spell_links.json"

const urlParams = new URLSearchParams(window.location.search);
const char = urlParams.get("char");

/*Function responsible for grabbing the spell images used to display the popular spells*/

function grab_image(character) {
    let spells = spell_recommendations[character];
    return <CreateDeck spells={spells} state={'box'}/>;
}

function deck_format_conversion(character,name,list) {
    let copyDeck_initial = character + ';'  + name + ';' +  'SpellCardSleeve;'
    let copyDeck_spells = ''
    for(let i = 0; i < list.length; ++i) {
        copyDeck_spells += list[i] + (i === list.length-1 ? "" : ",")
    }
    return copyDeck_initial + copyDeck_spells
}
//'deck-name-link'
/*
Function responsible for assembling each of the individual cells in the competitive deck table
Check if the deck name is listed in the deck_plan JSON object, and if it is, its tutorial link will be enabled. Otherwise there is no active link.
CreateDeck makes the spell list displayed in the deck list column
*/
function create_deck_table_entry(char, name, author, list){
    return (
    <tr>
        <td >
            <div className='character-deck-name-box'>
            {name in deck_plan? <a className='deck-name-link' href={'/deck_tutorial?deck_name=' + name + '&' + 'char=' + char}>{name}</a> : name}
            <ClipboardButton copyText={deck_format_conversion(char,name,list)}/>
            </div>
        </td>
        <td>{author}</td>
        <td>
            <CreateDeck spells={list}/>
        </td>
    </tr>
    );
}

/*Function responsible for assembling the competitive deck table. Every single deck object belonging to the selected character will have an entry created.*/

function CreateDeckTable ({character}){
    return (
        <tbody>
            {Object.entries(comp_decklists[character]).map(([key, value]) => (create_deck_table_entry(char, key, value.author, value.list)))}
        </tbody>
    );
}
/*  Function responsible for assembling deck lists. Spell names need to be accurate to their stored image file name otherwise this will break.
    State is for determining whether this function is being used to assemble a deck list for the popular spells section, or for a cell in the competitive deck table.
*/
// If the spell images don't load in vercel, check the folder names
function CreateDeck({spells, state}){
    return (
        <div className={state ==='box'? 'spellBox' : 'table-spell-container'}>
            {spells.map((spell, index) => (
                <a href={spell_links[spell]}>
                <img title={spell + ''} key={index} className={state==='box'? 'spell-img' : 'table-spell-image'} alt={'' + spell} src={'/images/spells/' + spell + '.png'}/>
                </a>
            ))}
        </div>
    );
}

/*  Function responsible for loading the information displayed on this page. Grabs the variable passed from the homepage to determine which character page to generate.
    Essentially the template for this page.
*/
function LoadChar(character){
    return (
        <div>
            <header className='header'>{character}</header>
            <div className='charBox'>
                <img className="charImg"alt='' src={'/images/'+ character + 'Banner.png'}/>
                <text className='charText'>{descriptions[character]}</text>
            </div>
            <div className='liked-spells'>
                <header className='liked-text'>Strong Spells</header>
                {grab_image(character)}
            </div>
            <div className='deck-container'>
                <header className='liked-text'>Competitive Decks</header>
                <div className='table-container'>
                    <table className='deck-table'>
                        <thead>
                            <tr>
                                <th scope='col'>Deck Name</th>
                                <th scope='col'>Decksmith</th>
                                <th scope='col'>Decklist</th>
                            </tr>
                        </thead>
                            <CreateDeckTable character={character}/>
                    </table>

                </div>
            </div>
        </div>
    );
}
// Calls the LoadChar function and feeds the character information into it so it knows what stuff to generate
function Character(){
    useEffect(()=>{
        document.title= char + " | Decks Of Eden"
    })
    return (
        <div className='body'>
            {LoadChar(char)}
        </div>
    )
}

export default Character;