import './deck_tutorial.css';
import comp_decklists from './comp_decklists.json';
import deck_plan from './deck_plan.json'
import { useEffect } from 'react';
import spell_links from "./spell_links.json"


const urlParams = new URLSearchParams(window.location.search);
const deck_name = urlParams.get("deck_name");
const char = urlParams.get("char");


/*  Function responsible for assembling deck lists. Spell names need to be accurate to their stored image file name otherwise this will break.
    State is for determining whether this function is being used to assemble a deck list for the popular spells section, or for a cell in the competitive deck table.
*/
function CreateDeck({spells, state}){
    return (
        <div className={state ==='box'? 'deck-spellBox' : 'deck-table-spell-container'}>
            {spells.map((spell, index) => (
                <a href={spell_links[spell]}>
                <img title={spell + ''} key={index} className={state==='box'? 'deck-spell-img' : 'deck-table-spell-image'} alt={'' + spell} src={'/images/spells/' + spell + '.png'}/>
                </a>
            ))}
        </div>
    );
}

/*
Function responsible for assembling each of the individual cells in the combo deck table
Takes in a number which is just for indexing purposes in display, not used for anything important
Takes in a sequence which is an array of spell and is used to generate a deck that is displayed in the table cell
Notation is just a text box
*/
function create_combo_table_entry(number, sequence,notation){
    return (
        <tr>
            <td >{number}</td>
            <td>
                <CreateDeck spells={sequence}/>
            </td>
            <td>{notation}</td>
        </tr>
    );
}

// Every combo object in deck_plan.json has a table entry created for it
// Key is the index for the combo, value is the combo, and current_deck['notation'][key] is the corresponding notation for the current combo
function CreateComboTable(){
    const current_deck = deck_plan[deck_name]
    return (
        <tbody>
            {Object.entries(current_deck['combos']).map(([key, value]) => (create_combo_table_entry(key, value, current_deck['notation'][key])))}
        </tbody>
    );
}



function DeckTutorial(){
    useEffect(()=>{
        document.title= deck_name + " | Decks Of Eden"
    })
    return(
        <div className='deck-body'>
            <header className='deck-header'>{'How To Play: '+  deck_name}</header>
            <header className='deck-header-author'>{'Guide by: ' + comp_decklists[char][deck_name]['author']}</header>
            <div className='deck-decklist'>
                <div className='deck-subheader-box'>
                    <header className='deck-subheader'>Decklist</header>
                </div>
                <CreateDeck spells={comp_decklists[char][deck_name]['list']} state='box'/>
            </div>
            <div className='deck-gameplan-container'>
                <header className='deck-gameplan-header'>Gameplan Summary</header>
                <div className='deck-gameplan-box'>
                    <text className='deck-gameplan-text'>{deck_plan[deck_name]['gameplan']}</text>
                </div>
            </div>
            <div className='deck-combo-container'>
                <header className='deck-gameplan-header'>Combos</header>
                <table>
                    <thead className='deck-thead'>
                        <tr>
                            <th scope='col'>No.</th>
                            <th scope='col'>Combo</th>
                            <th scope='col'>How to perform</th>
                        </tr>
                    </thead>
                        <CreateComboTable/>
                </table>
            </div>
        </div>
    );
}




export default DeckTutorial;