import React, { useState } from 'react';

function ClipboardButton({copyText}) {
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(copyText)
      .then(() => {
        alert("Deck list copied to clipboard!");
      })
      .catch(err => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div>
      <button className='character-deck-name-button' onClick={handleCopyToClipboard}>Copy</button>
    </div>
  );
}

export default ClipboardButton;
