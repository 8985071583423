import './forms.css';
import React, {useState} from "react";
import { useEffect } from 'react';



function Forms(){
    useEffect(()=>{
        document.title="Submit Deck | Decks Of Eden"
    })
    // useState for changing the google forms link depending on which form-navbar div gets clicked

    // Do not change unless the form link changes
    const [form_src, set_form_src] = useState("https://docs.google.com/forms/d/e/1FAIpQLSdq6IIMef9r2Qwe3kbty4W-QsQ4lnHG3TOcR1FLr9YuiXZ8Dw/viewform?embedded=true");
    const click_comp =  () => {
        set_form_src("https://docs.google.com/forms/d/e/1FAIpQLSdq6IIMef9r2Qwe3kbty4W-QsQ4lnHG3TOcR1FLr9YuiXZ8Dw/viewform?embedded=true")
    }
    const click_tutor = () => {
        set_form_src("https://docs.google.com/forms/d/e/1FAIpQLSex3LCJ2-Kxl_TGY6WyA3NemvcWjdmpGSTO7au7j4N4Fz3Ilw/viewform?embedded=true")
    }
    //iframe src should be equal to whatever the google form link is. In this case, it will be one of two links depending on which div is clicked.
    // To increase the number of options, add another click function and set the set_form_src variable to it
    return (
        <div className='forms-body'>
            <div className='forms-compform-container'>
                <div className='forms-form-navbar-container'>
                    <div onClick={click_comp}className='forms-form-navbar-left'>
                        <a >Competitive Deck Entry Form</a>
                    </div>
                    <div onClick={click_tutor}className='forms-form-navbar-right'>
                        <a >Deck Tutorial Entry Form</a>
                    </div>
                </div>
                    <iframe className="forms-googleform" src={form_src}  width="800" height="1961" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            </div>
        </div>
    );
}


export default Forms;