import './community.css'
import { useEffect } from 'react';

// Text displayed in the discord cards
const text_official = "This is the official Duelists of Eden Discord server. Here you'll find a large community filled with both Duelists of Eden players and One Step From Eden players. This is also the server where you are able to report bugs and give feedback on the game.";
const text_brasil = "The Discord server for Duelists of Eden players located in Brazil. Make sure to check out their Brasil Mana Boost tournament series!";
const text_japan = "The Discord server for Duelists of Eden players located in Japan.";
const text_oceania = "The Discord server for Duelists of Eden players located in Oceania.";
const text_sea = "The Discord server for Duelists of Eden players located in Southeast Asia.";
const text_europe = "The Discord server for Duelists of Eden players located in Europe. Make sure to check out their Duelists of EUden tournament series!";

// Card template function. Takes in a title, description, and an invite link to the discord server
function buildCard(title, description, link){
    return(
        <div className='community-discord-card'>          
            <div className='community-discord-header-flexbox'>
                <header className='community-discord-header'>{title}</header>
                <a href={link}>
                    <button className='community-discord-card-button'>Join</button>
                </a>     
            </div>
            <br/>
            <text className='community-discord-card-text'>{description}</text>  
        </div>
    );
}

// Nothing complex here. Ranked Banner link for advertisement, and a card grid for displaying the discord information
function Community(){
    useEffect(()=>{
        document.title="Community | Decks Of Eden"
    })
    return(
        <div className='community-body'>
            <div className='community-ranked-container'>
                <img className='community-ranked-banner'src='/images/RankedHappyHourBanner.png' alt=''></img>
            </div>
            <header className='community-meet-header'>Join The Duelists Community</header>
            <div className='community-discord-grid'>
                {buildCard("Official Duelists Of Eden Discord", text_official, 'https://discord.gg/osfe')}
                {buildCard("Duelists Of Eden Brasil", text_brasil, 'https://discord.gg/pQuf5tmmVQ')}
                {buildCard("Duelists Of Eden Japan", text_japan, 'https://discord.gg/xAr32AvHY2')}
                {buildCard("Duelists Of Eden Oceania", text_oceania, 'https://discord.gg/vB7qed6qw4')}
                {buildCard("Duelists Of Eden Southeast Asia", text_sea, 'https://discord.gg/GSFkm54Rxj')}
                {buildCard("Duelists Of Eden Europe", text_europe, 'https://discord.gg/EUwZ65Vr6G')}
            </div>
        </div>
    );
}

export default Community;