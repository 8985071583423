import './sidebar.css';

// Load the sidebar when the menubutton in Navbar.js is clicked
// Is open initially tells us if the bar is open, and toggle sidebar makes sure the
// state is updated accurately.

//Pretty much a component, don't touch unless the sidebar breaks
export default function Sidebar({isOpen ,toggleSidebar}){
    if (!isOpen){return null};
    return (
        <div className={'sidebar-menu-container'}>
            <img className="sidebar-x-button"onClick={toggleSidebar} alt='' src='./images/icons/sidebar_x.png'></img>
            <div className='sidebar-menu-buttons'>
                <a className="sidebar-button" href='/forms'>Submit Deck</a>
                <a className="sidebar-button" href='/community'>Community</a>
                <a className='sidebar-button' href='/about'>About</a>
            </div>
        </div>
    );
}